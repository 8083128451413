import React, { useState, useEffect } from "react";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
  LabelList
} from "recharts";

export default function Cashflow() {
  const today = new Date();
  const initialFromDate = new Date(today.getFullYear() - 1, today.getMonth(), 1);
  const [startDate, setStartDate] = useState(`${initialFromDate.getFullYear()}-${String(initialFromDate.getMonth()).padStart(2, '0')}`);
  const initialToDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const [endDate, setEndDate] = useState(`${initialToDate.getFullYear()}-${String(initialToDate.getMonth()).padStart(2, '0')}`);
  const [data, setData] = useState([]);

  // Function to get the last day of the selected end month
  const getLastDayOfMonth = (date) => {
    const newDate = new Date(date);
    return new Date(newDate.getFullYear(), newDate.getMonth() + 2, 0); // Day 0 returns the last day of the previous month
  };

  const formatDate = (year,month) => {
    const date = new Date(year, month-1, 1);
    const options = { year: 'numeric', month: 'short' };
    return date.toLocaleDateString('en-US', options); // "2024 Sep"
  };

  // Formatter function to format values
  const formatCurrency = (value) => {
    return `$${value.toLocaleString()}`; // Add dollar sign and format with commas
  };

  const fetchData = async () => {
    let jointCashflowResponse = await fetch(`/api/monthly-cashflow?fromDate=${startDate}&toDate=${getLastDayOfMonth(endDate)}&account=Joint Checking`, {
      credentials: "include",
    });
    let markCashflowResponse = await fetch(`/api/monthly-cashflow?fromDate=${startDate}&toDate=${getLastDayOfMonth(endDate)}&account=Mark's Savings`, {
      credentials: "include",
    });
    let mellCashflowResponse = await fetch(`/api/monthly-cashflow?fromDate=${startDate}&toDate=${getLastDayOfMonth(endDate)}&account=Mell's Savings`, {
      credentials: "include",
    });
    if (jointCashflowResponse.ok && markCashflowResponse.ok && mellCashflowResponse.ok) {
      let jointData = await jointCashflowResponse.json();
      let markData = await markCashflowResponse.json();
      let mellData = await mellCashflowResponse.json();
      
      let jointDataMap = {};
      jointData.forEach((item) => {
        jointDataMap[`${item.year}-${item.month}`] = {name: `${item.year}-${item.month}`,formattedName: formatDate(item.year,item.month), year: item.year, month: item.month, 'JointCheckingIn': Math.round(item.totalIncoming), 'JointCheckingOut': Math.round(item.totalOutgoing) * -1};
      });

      markData.forEach((item) => {
        jointDataMap[`${item.year}-${item.month}`] = {...jointDataMap[`${item.year}-${item.month}`], 'MarkSavingsIn': Math.round(item.totalIncoming), 'MarkSavingsOut': Math.round(item.totalOutgoing) * -1};
      });

      mellData.forEach((item) => {
        jointDataMap[`${item.year}-${item.month}`] = {...jointDataMap[`${item.year}-${item.month}`], 'MellSavingsIn': Math.round(item.totalIncoming), 'MellSavingsOut': Math.round(item.totalOutgoing) * -1};
      });

      Object.keys(jointDataMap).forEach((key) => { 
        let item = jointDataMap[key];
        jointDataMap[key] = { ...item, balance: item.JointCheckingIn + item.JointCheckingOut + item.MarkSavingsIn + item.MarkSavingsOut + item.MellSavingsIn + item.MellSavingsOut };
      });
      setData(Object.values(jointDataMap));
    }
  };

  // Function to handle click events on bars
  const handleBarClick = (data) => {
    const clickedYear = data.year;
    const clickedMonth = data.month;

    // Assuming you want to set the start and end date for the selected month
    const startOfMonth = `${clickedYear}-${clickedMonth}-01`; // Adjust based on your date format
    // Calculate the last day of the month dynamically
    const endOfMonthDate = new Date(clickedYear, clickedMonth, 0); // 0 gives the last day of the previous month, but we add 1
    const endOfMonth = `${clickedYear}-${clickedMonth}-${endOfMonthDate.getDate()}`; // This will give the last day
console.log(startOfMonth, endOfMonth);
    // Construct the URL with start and end date as query parameters
    const url = `/transactions?startDate=${startOfMonth}&endDate=${endOfMonth}`;
    
    // Navigate to the URL
    window.location.href = url; // Or use history.push if using react-router
  };

  useEffect(() => {
    if (startDate && endDate) fetchData();
  }, [startDate, endDate]);

  return (
    <div>
      {/* Date selectors */}
      <div style={{ margin: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
  <label htmlFor="start-date" style={{ fontWeight: 'bold', fontSize: '16px' }}>Start Date:</label>
  <input
    id="start-date"
    type="month"
    value={startDate}
    onChange={(e) => setStartDate(e.target.value)}
    style={{
      padding: '10px',
      borderRadius: '5px',
      border: '1px solid #ccc',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
      transition: 'border-color 0.3s',
      fontSize: '16px',
    }}
    onFocus={(e) => (e.target.style.borderColor = '#66bb6a')} // Change border color on focus
    onBlur={(e) => (e.target.style.borderColor = '#ccc')} // Reset border color on blur
  />

  <label htmlFor="end-date" style={{ fontWeight: 'bold', fontSize: '16px' }}>End Date:</label>
  <input
    id="end-date"
    type="month"
    value={endDate}
    onChange={(e) => setEndDate(e.target.value)}
    style={{
      padding: '10px',
      borderRadius: '5px',
      border: '1px solid #ccc',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
      transition: 'border-color 0.3s',
      fontSize: '16px',
    }}
    onFocus={(e) => (e.target.style.borderColor = '#66bb6a')} // Change border color on focus
    onBlur={(e) => (e.target.style.borderColor = '#ccc')} // Reset border color on blur
  />
</div>

      <div>
    <ResponsiveContainer width="100%" height={600}>
      <ComposedChart data={data} stackOffset="sign" margin={{ right: 30, left: 30 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="formattedName"/>
        <YAxis tickFormatter={(value) => formatCurrency(value)} /> {/* Format Y-axis labels */}
        <Tooltip formatter={(value) => formatCurrency(value)} /> {/* Format tooltip values */}
        <Legend />

        {/* Stacked Bars for Incoming Cashflows in different shades of green */}
        <Bar dataKey="JointCheckingIn" fill="#66bb6a" stackId="a" name="Joint Checking Incoming" onClick={(data) => handleBarClick(data)} />
        <Bar dataKey="MarkSavingsIn" fill="#81c784" stackId="a" name="Mark's Savings Incoming" onClick={(data) => handleBarClick(data)}/>
        <Bar dataKey="MellSavingsIn" fill="#a5d6a7" stackId="a" name="Mell's Savings Incoming" onClick={(data) => handleBarClick(data)}/>

        {/* Stacked Bars for Outgoing Cashflows in different shades of red */}
        <Bar dataKey="JointCheckingOut" fill="#ef5350" stackId="a" name="Joint Checking Outgoing" onClick={(data) => handleBarClick(data)}/>
        <Bar dataKey="MarkSavingsOut" fill="#e57373" stackId="a" name="Mark's Savings Outgoing" onClick={(data) => handleBarClick(data)}/>
        <Bar dataKey="MellSavingsOut" fill="#ef9a9a" stackId="a" name="Mell's Savings Outgoing" onClick={(data) => handleBarClick(data)}/>

        <Line type="monotone" dataKey="balance" stroke="#282828" strokeWidth={2} name="Cashflow Balance" >
          {/* Adding labels to show balance value */}
          <LabelList 
          dataKey="balance" 
          position="top" 
          style={{ fill: '#282828', fontWeight: 'bold' }}
          formatter={(value) => formatCurrency(value)} // Add dollar sign to balance
          offset={20}
        />
        </Line>
      </ComposedChart>
    </ResponsiveContainer>
  </div>
    </div>
  );
}
