import React, { useState, useEffect, useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";

export default function Header(props) {
  const location = useLocation();
  const [pathName, setPathName] = useState(null);

  // Close the dropdown menus if the user clicks outside of it
  window.onclick = function(event) {
    document.querySelector('#navItems').classList.remove('show');
    document.getElementById("chartDropDownMenu").classList.remove("show");
    document.getElementById("adminDropDownMenu").classList.remove("show");
  }

  useEffect(() => {
    setPathName(location.pathname);
  }, [location]);

  const toggleAdminDropdown = (event) => {
    event.stopPropagation();
    document.querySelector('#chartDropDownMenu').classList.remove('show');
    document.querySelector('#adminDropDownMenu').classList.toggle('show');
  }
  const toggleChartDropdown = (event) => {
    event.stopPropagation();
    document.querySelector('#adminDropDownMenu').classList.remove('show');
    document.querySelector('#chartDropDownMenu').classList.toggle('show');
  }

  const toggleNavItems = function(event){
    event.stopPropagation();
    document.querySelector('#navItems').classList.toggle('show');
  }

    return (
    <nav className="navbar navbar-expand-md navbar-dark bg-dark sticky-top">
      <a className="navbar-brand" href="/">
        <img src="/images/apple-icon-60x60.png" width="30" className="d-inline-block align-top mr-1" alt="" loading="lazy"/>
        Household
      </a>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={toggleNavItems}>
            <span className="navbar-toggler-icon"></span>
          </button>

      <div className="collapse navbar-collapse" id="navItems">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item">
            <NavLink className="nav-link" activeClassName="active" exact to="/">Home</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" activeClassName="active" exact to="/transactions">Transactions</NavLink>
          </li>
          <li className="nav-item dropdown">
            <span className={`nav-link dropdown-toggle ${pathName && pathName.includes('report') && 'active'}`} style={{cursor:'pointer'}} onClick={toggleChartDropdown}>Reports</span>
            <div id="chartDropDownMenu" className="dropdown-menu" aria-labelledby="navbarDropdown">
              <NavLink className="dropdown-item" to="/report/expenseGroups" >Expense Report</NavLink>
              <NavLink className="dropdown-item" to="/report/budgetForecasting" >Budget Forecasting</NavLink>
              <NavLink className="dropdown-item" to="/report/cashFlow" >Cashflow</NavLink>
            </div>
          </li>
          <li className="nav-item dropdown">
            <span className={`nav-link dropdown-toggle ${pathName && pathName.includes('admin') && 'active'}`} style={{cursor:'pointer'}} onClick={toggleAdminDropdown}>Admin</span>
            <div id="adminDropDownMenu" className="dropdown-menu" aria-labelledby="navbarDropdown">
              <NavLink className="dropdown-item" to="/admin/budgetDefs" >Budget Defs</NavLink>
              <NavLink className="dropdown-item" to="/admin/projects" >Projects</NavLink>
              <NavLink className="dropdown-item" to="/admin/expenseGroups" >Expense Groups</NavLink>
              <NavLink className="dropdown-item" to="/admin/logs" >Logs</NavLink>
            </div>
          </li>
        </ul>
        <div className="my-2 my-lg-0 text-white">
          {props.user.firstName} {props.user.lastName}
        </div>
      </div>
    </nav>
    )
}